import { useSelector, useDispatch } from 'react-redux'
import {
  selectFormdata
} from '../../Form/FormSlice'


function runChecks(formData) {
    if (formData.campaignId == '') {
        return [false, 'The campaignid']
    }
    else if (formData.startDate == '') {
        return [false, 'Start date']
    }
    else if (formData.endDate == '') {
        return [false, 'End date']
    }
    else if (formData.metricType === 1 && formData.continuousMetrics.length == 0) {
         return [false, 'Continuous Metrics']
    }
    else if (formData.metricType === 0 && formData.metrics.length == 0) {
         return [false, 'Metrics']
    }
    else if (formData.dimensions.length == 0) {
        return [false, 'Dimensions']
    }
    else {
        return [true, '']
    }
}

export default function RunQueries(formData, token, setLoading, setOpen, setContent) {
    var checks;
    var field;
    setLoading(true)
    let dashboardId = 1209;
    if (formData.metricType === 1) {
        dashboardId = 4610;
    }
    checks = runChecks(formData)
    console.log(token)
    if (checks[0]) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(formData),
        };
        let domain = process.env.REACT_APP_BACKEND_HOST_URL;
        fetch(domain + "/react_endpoint_ga4", requestOptions)
            .then(function (body) {
                return body.text();
            })
            .then(function (data) {
                if (data != 'success') {
                    alert(data);
                }
                else {
                    const newUrl = `https://coolblue.cloud.looker.com/dashboards/${dashboardId}?Campaign+ID=${formData.campaignId}&Metric+Name=&Segment=&Segment+Value=`
                    setOpen(true)
                    setContent(<div>Your data is ready, you can find it in this Looker <a href={newUrl} target="_blank">dashboard</a></div>)
                }

            })
            .finally(() => {
                setLoading(false)
            })
    } else {
        field = checks[1]
        alert(`${field} has not yet been filled in.`)
        setLoading(false)
    }
}



