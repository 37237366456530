import React, { Component, useState, useEffect } from "react"
import ReactGA from "react-ga4";
import { Autocomplete, Box, Chip, Checkbox, Button, DataGrid, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import Edit from '@mui/icons-material/Edit';
import { platformArray } from "../../Form/StandardMetricsDimsMap";
import ListItemText from '@mui/material/ListItemText';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, NavLink } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { GridToolbar } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import  SaveData from '../BackendAPI/SaveData'
import { Dialog, DialogTitle, DialogContent, DialogContentText} from '@coolblue-development/becky';
import { QuestionmarkCircled, Cross} from '@coolblue-development/icons';
import {info} from '../../Form/Info'


export default function CalculatorDetails() {
    const navigate = useNavigate();

    const types = ["Minimum Detectable Effect", "Sample size (Days)"]
    const [type, setType] = useState(['Minimum Detectable Effect'])

    const metrics = ['add_to_cart', 'placed_order_conversion', 'checkout_conversion_rate', 'contact_option_clicked',
    'solar_conversion', 'energy_conversion', 'charging_stations_conversion', 'custom']
    const [metric, setMetric] = useState(['placed_order_conversion'])

    const platforms = ['desktop', 'mobile', 'tablet', 'app']
    const [platform, setPlatform] = useState(['desktop', 'mobile', 'tablet'])

    const subsidiaries = ['Netherlands', 'Belgium', 'Germany']
    const [subsidiary, setSubsidiary] = useState(subsidiaries)

    const [traffic, setTraffic] = useState('')
    const [variations, setVariations] = useState('1')

    const variable = {type:'Minimum Detectable Effect', metric:'placed_order_conversion', variations:'1', mde:'', traffic:'', event_name:'.*',
    context:'.*', feature:'.*', sub_feature:'.*', URL:'.*', platform:['desktop', 'mobile', 'tablet'],
    subsidiary:['NL', 'BE', 'DE']}
    const [inputData, setInputData] = useState(variable)
    const auth = useAuth()
    const token = auth.user.token;
    const appBarUsername = auth?.user?.email.split('@')[0];

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [infoModalcontent, setInfoModalContent] = useState('');
    const [results, setResults] = useState([]);
    const [column_1, setColumn_1] = useState('Weeks');
    const [column_2, setColumn_2] = useState('Days');
    const [column_3, setColumn_3] = useState('MDE');
    const [visits, setVisits] = useState('Daily Visits: 1000');
    const [baseRate, setBaseRate] = useState('2.33%');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setInputData((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));
    };

    const handleTypeChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['type']: value,
        }));
        setType(value)
    };

    const handleMetricChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['metric']: value,
        }));
        setMetric(value)
    };

    const handlePlatformChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['platform']: value
        }));
        setPlatform(value)
    };

    const handleSubsidiaryChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['subsidiary']: value,
        }));
        setSubsidiary(value)
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleInfoModalOpen = (fieldTopic): void => {
      setInfoModalOpen(true);
      setInfoModalContent(info[fieldTopic])
    };
    const handleInfoModalClose = (): void => {
      setInfoModalOpen(false);
    };

    const handleCalculate = (): void => {
        SaveData(inputData, setLoading, token, setOpen, setResults, setColumn_1, setColumn_2, setColumn_3, setVisits, setBaseRate);
        ReactGA.gtag('event', 'submit', {
          category: appBarUsername,
          action: window.location.hash
        });
    }

    const columns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 10,
        editable: false,
      },
      {
        field: 'weeks',
        headerName: column_1,
        width: 150,
        editable: false,
      },
      {
        field: 'days',
        headerName: column_2,
        width: 150,
        editable: false,
      },
      {
        field: 'mde',
        headerName: column_3,
        width: 300,
        editable: false,
      }
      ]

const detailsrows = results.map((row, index) => {
        return {
            id: index + 1,
            weeks: row.weeks,
            days: row.days,
            mde: row.mde
        }
    })

    return (
    <Grid container>
        <Grid item xs={12}>
            <Box>
                <Typography variant="h2" gutterBottom component="div">
                    General information
                </Typography>
            </Box>
        </Grid>

    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={3} paddingRight={6}>
            <FormControl fullWidth>
                <InputLabel id="type">To calculate</InputLabel>
                <Select
                    labelId="type"
                    name="type"
                    defaultValue={type}
                    value={type}
                    onChange={handleTypeChange}
                    label="To calculate"
                    placeholder="Select one.."
                >
                {types.map((name) => (
                    <MenuItem value={name}>{name}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <FormControl fullWidth>
                <InputLabel id="metric">Metric</InputLabel>
                <Select
                    labelId="metric"
                    name="metric"
                    defaultValue={metric}
                    value={metric}
                    onChange={handleMetricChange}
                    label="Metric"
                >
                {metrics.map((name) => (
                    <MenuItem value={name}>{name.replaceAll('_', ' ')}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>

        {metric === 'custom' && (
            <Grid item xs={3} paddingRight={6}>
                <TextField fullWidth
                   InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('custom_metric_value')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
                   name="custom_metric_value"
                   label="Custom Metric Value"
                   placeholder=""
                   value={inputData.custom_metric_value}
                   onChange={e => { handleChange(e)}}
                   placeholder="E.g. 0.029"
                />
            </Grid>
         )}
    </Grid>

    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('variations')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               name="variations"
               label="Amount of variations"
               placeholder=""
               value={inputData.variations}
               onChange={e => { handleChange(e)}}
               placeholder="Use an integer"
            />
        </Grid>

        {type === 'Sample size (Days)' && (
            <Grid item xs={3} paddingRight={6}>
                <TextField fullWidth
                   InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('mde')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
                   name="mde"
                   label="Minimum Detectable Effect"
                   placeholder=""
                   value={inputData.mde}
                   onChange={e => { handleChange(e)}}
                   placeholder="E.g. 0.01"
                />
            </Grid>
         )}

        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('traffic_per_day')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               name="traffic"
               label="Traffic per day (optional)"
               value={inputData.traffic}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

    </Grid>

    {inputData.traffic === "" && (
        <Grid container xs={12} paddingTop={4}>
            <Grid item xs={12}>
                <Box>
                    <Typography variant="h2" gutterBottom component="div">
                        Test details
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )}

    {inputData.traffic === "" && (
    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('event_name')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               name="event_name"
               label="Event Name"
               value={inputData.event_name}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('context')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               name="context"
               label="Context"
               value={inputData.context}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('feature')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               name="feature"
               label="Feature"
               value={inputData.feature}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleInfoModalOpen('sub_feature')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               name="sub_feature"
               label="Sub-feature"
               value={inputData.sub_feature}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>
    </Grid>
    )}

    {inputData.traffic === "" && (
        <Grid container xs={12} paddingTop={4} paddingBottom={4}>
            <Grid item xs={3} paddingRight={6}>
                <TextField fullWidth
                   name="URL"
                   label="Page Location"
                   value={inputData.URL}
                   onChange={e => { handleChange(e)}}
                   placeholder=""
                />
            </Grid>

            <Grid item xs={4} paddingBottom={4} paddingRight={6}>
                   <Autocomplete fullWidth
                     multiple
                     name="platform"
                     value={platform}
                     onChange={(event, newValue) => {
                       setPlatform(newValue);
                       setInputData((prevState) => ({
                           ...prevState,
                           ['platform']: newValue,
                       }));
                     }}
                     options={platforms}
                     filterSelectedOptions
                     renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                       <TextField {...params} label="Platform"/>
                     )}
                   />
            </Grid>

            <Grid item xs={4} paddingBottom={4} paddingRight={6}>
                   <Autocomplete fullWidth
                     multiple
                     name="subsidiary"
                     value={subsidiary}
                     onChange={(event, newValue) => {
                       setSubsidiary(newValue);
                       setInputData((prevState) => ({
                           ...prevState,
                           ['subsidiary']: newValue,
                       }));
                     }}
                     options={subsidiaries}
                     filterSelectedOptions
                     renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                       <TextField {...params} label="Subsidiary"/>
                     )}
                   />
            </Grid>

        </Grid>
    )}

    <Box sx={{ height: 10 }} />
         <Divider />
            <Box sx={{ height: 10 }} />
            <Grid item xs={3} paddingTop={4}>
                <Box>
                    <Button
                        preset="callToAction"
                        loading={loading}
                        onClick={() => handleCalculate()}
                    >
                        Calculate
                    </Button>
                    <Dialog size="large" open={open} onClose={handleClose}>
                        <DialogTitle>
                        Result
                        <IconButton
                            onClick={handleClose}
                            preset="text"
                            size="small"
                            aria-label="Close dialog"
                        >
                          <Cross/>
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 4,
                          flexWrap: 'wrap',
                          paddingTop: 4
                        }}
                      >
                        <Chip label={baseRate} />
                        <Chip label={visits} />
                        </Box>
                        <DataGrid
                            autoHeight
                            rows={detailsrows}
                            columns={columns}
                            initialState = {
                                {
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false,
                                            label: false
                                        },
                                    },
                                    sorting:
                                    {
                                        sortModel: [{ field: 'start_date', sort: 'desc' }],
                                    }
                            }}
                            slots={{ toolbar: GridToolbar }}
                        />
                      </DialogContent>
                    </Dialog>
                <Dialog open={infoModalOpen} onClose={handleInfoModalClose}>
                    <DialogTitle>
                        A little help
                        <IconButton
                            onClick={handleInfoModalClose}
                            preset="text"
                            size="small"
                            aria-label="Close dialog"
                        >
                            <Cross />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>{infoModalcontent}</DialogContentText>
                    </DialogContent>
                </Dialog>
                </Box>
            </Grid>
    </Grid>
    );
}
