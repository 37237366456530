import React  from "react";
import {
	Box,
	Button,
	Grid,
	Typography
} from "@coolblue-development/becky";
import { NavLink } from "react-router-dom";
import TestOverview from "../Components/TestOverview";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import Login from "../../SharedComponents/Login";
import trackPageview from "../../Analytics/useAnalyticsEventTracker";


export default function Home() {
    trackPageview()
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box>
                    <Typography variant="h2" gutterBottom component="div">
                        Test overview
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <TestOverview />
                </Box>
            </Grid>
            <Grid container xs={12} paddingBottom={4}>
                <Grid item xs={2} paddingTop={4}>
                    <Box>
                        <Button
                            preset="callToAction"
                            component={NavLink}
                            to="/AIAnalysis/Details?id=template">
                            New configuration
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}
