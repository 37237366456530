import React from "react";
import ReactGA from "react-ga4";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import { useSelector, useDispatch } from 'react-redux'
import {
    selectFormdata,
} from '../Form/FormSlice'

export function getappUsername() {
    const formData = useSelector(selectFormdata);
    const auth = useAuth();
    var appBarUsername = formData.username;
    if (!auth) {
        appBarUsername = formData.username;
    }
    else {
        appBarUsername = auth?.user?.email.split('@')[0];
    };
    return appBarUsername
}

export default function TrackPageview() {
    let appBarUsername = getappUsername()
    let pagepath = window.location.hash

    ReactGA.gtag('event', 'screen_view', {
      category: appBarUsername,
      action: pagepath
    });
}