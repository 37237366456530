import React, { useState, useEffect }  from "react";
import {
    Avatar,
	Box,
	Drawer,
	  TextField,
  Button,
  Typography,
  Grid
} from "@coolblue-development/becky";
import { Becky } from '@coolblue-development/icons';
import {red, green, blue, gray, peppermint, antarcticblue} from '../../SharedComponents/Colors'
import TestDetails from "../Components/TestDetails";
import MessageBar from "../Components/MessageBar";
import Conversation from "../Components/Conversation";
import { Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import { selectFormdata } from "../../Form/FormSlice";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import Login from "../../SharedComponents/Login";
import trackPageview from "../../Analytics/useAnalyticsEventTracker";

export default function Details() {
    trackPageview()
    const formData = useSelector(selectFormdata);
	const messages = formData.messages;

    const [open, setOpen] = useState(false);

	const handleOpen = (fieldTopic): void => {
		setOpen(true);
	};
    const handleClose = (): void => {
      setOpen(false);
    };

    return (
        <>
            <Box>
                <TestDetails />
            </Box>
            <Avatar sx={{position: "fixed", bottom: "50px;", right:"50px;", height: "60px;", width: "60px;" }} onClick={handleOpen}><Becky /></Avatar>
            <Drawer
              title="Chat Dialog"
              open={open}
              onClose={(): void => {
                handleClose(false);
              }}
              footer={<MessageBar/>}
            >
               <Conversation/>
            </Drawer>
        </>
    );
}