import React, { useState }  from "react";
import {
	Box
} from "@coolblue-development/becky";
import {red, green, blue, gray, peppermint, antarcticblue} from '../../SharedComponents/Colors'
import CalculatorDetails from "../Components/CalculatorDetails";
import TrackPageview from "../../Analytics/useAnalyticsEventTracker";


export default function Details() {
    TrackPageview()
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const handleClose = (): void => {
      setOpen(false);
    };
    return (
        <Box>
            <CalculatorDetails />
        </Box>
    );
}