import { Box } from "@coolblue-development/becky";
import { BeckyThemeProvider } from '@coolblue-development/themes';
import {
  Route,
  Routes,
  HashRouter,
  RouterProvider

} from "react-router-dom";


import Home from "./Screens/Home";

import CustomAnalysisHome_GA4 from "./CustomAnalysis_GA4/Screens/CustomAnalysisHome";
import CustomMetric_GA4 from "./CustomAnalysis_GA4/Screens/CustomMetric";
import CustomDimension_GA4 from "./CustomAnalysis_GA4/Screens/CustomDimension";
import StandardMetric_GA4 from "./CustomAnalysis_GA4/Screens/StandardMetric"
import StandardDimension_GA4 from "./CustomAnalysis_GA4/Screens/StandardDimension"

import AIAnalysisHome from "./AIAnalysis/Screens/AIAnalysisHome"
import AIAnalysisDetails from "./AIAnalysis/Screens/Details"

import { AuthProvider, useAuth } from '@coolblue-development/react-cognito-sso-auth';

import { getAuthConfig } from "./Auth/CognitoAuthConfig"
import router from './router';


import AppBarComponent from "./SharedComponents/Appbar";
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-CCR5TYBJFD"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

const authConfig = getAuthConfig();

export default function Main(){

    return (

      <BeckyThemeProvider>
      <AuthProvider config={authConfig}>
        <AppBarComponent />
          <Box
            sx={{
              bgcolor: 'background.paper',
              padding: 6,
              margin: 6
            }}
          >
            <RouterProvider router={router} />
          </Box>

      </AuthProvider>
      </BeckyThemeProvider>
    );
}