import React  from "react";
import {
	Box,
    Button,
    Typography,
    Grid
} from "@coolblue-development/becky";
import Login from "../SharedComponents/Login";
import {boxStyle} from '../SharedComponents/Styles'
import { NavLink } from "react-router-dom";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';

export default function Details() {
        return (
            <Grid container>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h1" gutterBottom component="div">
                      Split testing toolkit
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h2" gutterBottom component="div">
                            Custom Analysis Tool
                        </Typography>
                        <p>
                            If you can not find the metric or dimension you are looking for in our split testing dashboard by default, then we offer the opportunity to create extra metrics via the custom analysisis tool.
                            You can create your extra metric here to continue your analysis. Of course in GA4.
                        </p>
                        <Button
                            component={NavLink}
                            to="/CustomAnalysis_GA4/"
                        >
                            Custom Analysis Tool
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h2" gutterBottom component="div">
                            AI Analysis Tool
                        </Typography>
                        <p>
                            Do you want Artifical Intelligence or Large Language Models to help you to analyze your test? Then this tool is what you are looking for.
                        </p>
                        <Button
                            component={NavLink}
                            to="/AIAnalysis/"
                        >
                            AI Analysis Tool
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h2" gutterBottom component="div">
                            Tracking Configurator
                        </Typography>
                        <p>
                            This is the interface to make sure that an A/B test get's tracked. Please fill this in for every test.
                        </p>
                        <Button
                            component={NavLink}
                            to="/TrackingConfigurator/"
                        >
                            Tracking Configurator
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h2" gutterBottom component="div">
                            Impact calculator
                        </Typography>
                        <p>
                            Use this sheet to calculate the impact of your experiment.
                        </p>
                        <Button
                            component={NavLink}
                            to="https://docs.google.com/spreadsheets/d/1qt0nKI7aKwKhNiI1-hI_D6nL5SHmFOwLuCHZY4_8tqQ/edit#gid=0"
                        >
                            Impact calculator
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h2" gutterBottom component="div">
                            Split test variation calculator
                        </Typography>
                        <p>
                            Use this tool to find out how many variations you can add to your split test. Choose a metric, segment and expected uplift and see how many variations you can add.
                        </p>
                        <Button
                            component={NavLink}
                            to="https://docs.google.com/spreadsheets/d/1PQH4cZL7mLMBXUMWj7_oACdIKcnWZnX9X9dUGCbjFX4/edit#gid=1555154184"
                        >
                            Variation Calculator
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={boxStyle}>
                        <Typography variant="h2" gutterBottom component="div">
                            Sample Size Calculator
                        </Typography>
                        <p>
                            Use this tool to find out how many observations you need to significantly detect the metric uplift you want to achieve.
                        </p>
                        <Button
                            component={NavLink}
                            to="/SampleSizeCalculator/"
                        >
                            Sample Size Calculator
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
}
