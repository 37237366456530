import React, { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";

export default function useFetchDetails(setDetails, setMetricValue, setLoading, token, campaign_id) {
    useEffect(() => {
        let domain;
        domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        let backendHostUrl = domain + "/experiment_details?id=" + campaign_id
        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token }
        };
        setLoading(true);
        fetch(backendHostUrl, requestOptions)
            .then(res => res.json())
            .then(function(result) {
                setDetails({...result})
                setMetricValue(result.metrics)
            }
            )
            .finally(() => setLoading(false))
    }, [])
}