export default function SaveData(inputData, setLoading, token, setOpen, setResults, setColumn_1, setColumn_2, setColumn_3, setVisits, setBaseRate) {
        setLoading(true)
        let domain;
        domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        let backendHostUrl = domain + "/sequential"
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(inputData),
        };
        fetch(backendHostUrl, requestOptions)
            .then(function (body) {
                return body.text();
            })
            .then(function (data) {
                if (data == 'no success') {
                    alert(data);
                } else {
                    setLoading(false)
                    setOpen(true)
                    console.log(data)
                    setResults(JSON.parse(data)['data'])
                    setColumn_1(JSON.parse(data)['column_name_1'])
                    setColumn_2(JSON.parse(data)['column_name_2'])
                    setColumn_3(JSON.parse(data)['column_name_3'])
                    setVisits(JSON.parse(data)['visits'])
                    setBaseRate(JSON.parse(data)['base_rate'])
//                    window.location.href = "/SampleSizeCalculator";
                }
            })
}