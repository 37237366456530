import React, { Component, useState, useEffect } from "react"
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import { useSelector, useDispatch } from 'react-redux'
import { QuestionmarkCircled, Cross } from '@coolblue-development/icons';
import {
  selectFormdata,
    updateCampaignId,
    updateStartDate,
    updateEndDate,
    updateTestType,
    updateTruncate,
    addMetric,
    removeMetric
} from '../../Form/FormSlice'
import {info} from '../../Form/Info'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export default function GeneralInformation() {
  const formData = useSelector(selectFormdata);
  const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const handleOpen = (fieldTopic): void => {
      setOpen(true);
      setContent(info[fieldTopic])
    };
    const handleClose = (): void => {
      setOpen(false);
    };



    return (

      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h2" gutterBottom component="div">
              General information
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} paddingBottom={4}>
          <Box>
            <TextField
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleOpen('campaignId')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               label="CampaignId"
               placeholder="e.g. '1s4g41'"
               value ={formData.campaignId}
               helperText={!formData.campaignId.match('^$|^.{6}$')?"A campaign id is 6 characters long.":""}
               onChange={(e) => {
                     dispatch(updateCampaignId(e.target.value));
                   }}
            />
          </Box>
        </Grid>
        <Grid item xs={2} paddingBottom={4}>
          <Box>
            <TextField
               label="Start date"
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleOpen('startDate')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               placeholder="YYYY-MM-DD"
               value ={formData.startDate}
               helperText={!formData.startDate.match('^$|\\d{4}-\\d{2}-\\d{2}')?"Date format is YYYY-MM-DD.":""}
               onChange={(e) => {
                     dispatch(updateStartDate(e.target.value));
                   }}
            />

          </Box>
        </Grid>
        <Grid item xs={2} paddingBottom={4}>
          <Box>
            <TextField
               label="End date"
               placeholder="YYYY-MM-DD"
               InputProps={{endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleOpen('endDate')}><QuestionmarkCircled/></ IconButton > </InputAdornment>}}
               value ={formData.endDate}
              helperText={!formData.endDate.match('^$|\\d{4}-\\d{2}-\\d{2}')?"Date format is YYYY-MM-DD.":""}

               onChange={(e) => {
                     dispatch(updateEndDate(e.target.value));
                   }}
            />
          </Box>
        </Grid>
        <Grid item xs={2} paddingBottom={4}>
        <Box paddingRight={4}>
          <FormControl fullWidth>
            <InputLabel id="testype">Test type</InputLabel>
            <Select
              labelId="testype"
              id="testype"
              defaultValue={formData.testType}
              value={formData.testType}
              label="Test type"
              onChange={(e) => {
                     dispatch(updateTestType(e.target.value));
              }}>
              <MenuItem value={'precart'}>Pre cart</MenuItem>
              <MenuItem value={'precart_prdt'}>Pre cart - product type analysis</MenuItem>
              <MenuItem value={'checkout'}>Checkout</MenuItem>
              <MenuItem value={'energy'}>Energy</MenuItem>
              <MenuItem value={'charging_stations'}>Charging Stations</MenuItem>
              <MenuItem value={'solar'}>Solar Panels</MenuItem>
              <MenuItem value={'app'} disabled>App</MenuItem>
              <MenuItem value={'app_checkout'} disabled>App Checkout Only</MenuItem>
              <MenuItem value={'ftr_cbd'} >First Time Right (Coolblue bezorgt)</MenuItem>
              <MenuItem value={'ftr_dp'} >First Time Right (Delivery Partners)</MenuItem>
              <MenuItem value={'contact_ratio'}>Contact ratio</MenuItem>
              <MenuItem value={'contact_ratio_after_order'}>Contact ratio after order</MenuItem>
              <MenuItem value={'contact_ratio_self_service'}>Contact ratio self service</MenuItem>
              <MenuItem value={'return_ratio'}>Return ratio</MenuItem>
              <MenuItem value={'return_ratio_after_order'}>Return ratio after order</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>
        <Grid item xs={2} paddingBottom={4}>
        <Box paddingRight={4}>
          <FormControl fullWidth>
            <InputLabel id="truncatelabel">Overwrite results in looker</InputLabel>
            <Select
              labelId="truncatelabel"
              id="truncate"
              defaultValue={formData.truncate}
              value={formData.truncate}
              label="Overwrite the results in looker"
              onChange={(e) => {
                     dispatch(updateTruncate(e.target.value));
              }}>
              <MenuItem value={'yes'}>Yes</MenuItem>
              <MenuItem value={'no'}>No</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Grid>
              <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        A little help
        <IconButton
          onClick={handleClose}
          preset="text"
          size="small"
          aria-label="Close dialog"
        >
          <Cross />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
    </Dialog>
      </Grid>
    )
}
