import React, { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom";

export default function useFetchDetails(setDetails, setLoading, setPlatform, setDomain, setMetricLabel, setMetric, setSecondMetric, setHostname, token) {
    const [searchParams, setSearchParams] = useSearchParams();
    let id = searchParams.get("id")

    useEffect(() => {
        let domain;
        domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        let backendHostUrl = domain + "/tc_config?id=" + id
        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token }
        };
        setLoading(true);
        fetch(backendHostUrl, requestOptions)
            .then(res => res.json())
            .then(function(result) {
                setDetails({...result})
                setPlatform(result.platform)
                setDomain(result.domain)
                setMetricLabel(result.label)
                setMetric(result.metric)
                setSecondMetric(result.second_metric)
                setHostname(result.hostname)

            }
            )
            .finally(() => setLoading(false))
    }, [])
}