import { AuthConfigInterface } from '@coolblue-development/react-cognito-sso-auth';

export const getAuthConfig = (): AuthConfigInterface => {
    let domain = process.env.REACT_APP_COGNITO_DOMAIN;
    let oidc = process.env.REACT_APP_OIDC_CLIENT_ID;
    let user_pool = process.env.REACT_APP_USERPOOL_ID;

    const currentHostUrl = `${window.location.protocol}//${window.location.host}`;
    console.log(currentHostUrl)
    return {
          region: 'eu-west-1',
          userPoolId: user_pool,
          userPoolWebClientId: oidc,
          oauth: {
            domain: domain,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: currentHostUrl + "/",
            redirectSignOut: currentHostUrl + '/logout',
            responseType: 'code',
            client_id: oidc
          }
        }
};